
export function graphql(url, { headers }) {
  return function (query) {
    return async function (variables) {
      const results = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          ...headers
        },

        body: JSON.stringify({
          query: query,
          variables: variables
        })
      })
      
      const data = await results.json()

      if (data.errors)
        throw({status: results.status, errors: data.errors})
      
      data.status = results.status
      data.headers = results.headers
      return data
    }
  }
}
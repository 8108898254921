import { React } from 'mcelroy-lib'
import {graphql} from './graphql.js'

let proxyHeader = null
let graphURL = 'https://api.mcelroy.com/corpgraphql'
const apiToken = getToken()

export function setLocalAPI(doSet) {
    if (doSet)
        graphURL = 'https://me.mcelroy.com:8443/corpgraphql'
    else
        graphURL = 'https://api.mcelroy.com/corpgraphql'
}

export function setOverrideAPI(overrideURL) {
    if (overrideURL && overrideURL.startsWith('https://')) {
        overrideURL = overrideURL.substring(8)
        const idx = overrideURL.indexOf("/")
        if (idx > 0)
            overrideURL = overrideURL.substring(0, idx)
    }
    if (!overrideURL || overrideURL == 'api.mcelroy.com') {
        graphURL = 'https://api.mcelroy.com/corpgraphql'
        proxyHeader = null
    } else if (overrideURL == 'me.mcelroy.com') {
        graphURL = "https://me.mcelroy.com:8443/corpgraphql"
        proxyHeader = null
    } else {
        graphURL = 'https://csp.mcelroy.com/corpgraphql'
        proxyHeader = overrideURL
    }
}

/*
    Get API token from either a url param or from session storage.
    TODO: refresh url history to remove token param.
*/
function getToken() {
    const urlParams = window?.location.search.split(/[&?]/)
    if (urlParams) {
        for (let i = 0; i < urlParams.length; i++) {
            let param = urlParams[i].split("=")
            if (param[0] == '_t') {
                sessionStorage?.setItem("apiToken", param[1])
                return param[1]
            }
        }
    }
    return sessionStorage.getItem("apiToken")
}

/*
    Turns graphql into a React hook
*/
export function useAPI(query, vars, url) {
    const [data, setdata] = React.useState(null)
    const [loading, setloading] = React.useState(true)
    const [error, seterror] = React.useState(null)
    const [status, setstatus] = React.useState(null)
    const [headers, setheaders] = React.useState(null)

    function doQuery(q, v) {
        const requestHeaders = { "API_AUTH_TOKEN": apiToken }
        if (proxyHeader)
            requestHeaders.proxy_host = proxyHeader

        const graph = graphql(url ?? graphURL, { asJSON: true, headers: requestHeaders })
        const graphQuery = graph(q)
        setloading(true)
        return new Promise(function (resolve, fail) {
            graphQuery(v)
                .then(function ({ status, data, headers }) {
                    setloading(false)
                    setdata(data)
                    seterror(null)
                    setstatus(status)
                    setheaders(headers)
                    resolve(data)
                })
                .catch(function ({status, errors, headers}) {
                    setdata(null)
                    seterror(errors)
                    setloading(false)
                    setstatus(status)
                    setheaders(headers)
                    fail(errors)
                })
        })
    }

    function run(newVars) {
        const combinedVars = {...vars, ...newVars}
        return doQuery(query, combinedVars)
    }

    function rerun() {
        doQuery(query, vars).catch(function(err){/*ignore*/})
    }

    React.useEffect(function () {
        if (query.trim().startsWith('query')) {
            rerun()
        }
    }, [query, ...(vars ? Object.values(vars) : [])])

    return { data, loading, error, status, headers, rerun, run }
}